import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditAccordion from './EditAccordion';
import { AllFroms } from '../../store/AppContext';
import BASE_URL from '../../config';
import toast from 'react-hot-toast';


const CustomFormEdit = () => {
    const navigate = useNavigate();
    let { customid } = useParams();
    
    const { customineFrom, setCustomineFrom,usertype} =  useContext(AllFroms);
    
    // const [templateData , setTemplateData] = useState({});
    const [auditname , setAuditName] = useState('');
    const [templatename , setTemplatename] = useState('');

    useEffect(()=>{
        async function GetCustomTemplate()
        {
            const response = await fetch(`${BASE_URL}/getsinglecustomaudit`, {
                method: 'POST',
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({customid})
            });
            var formData = await response.json();
            console.log(formData);
            if (response.ok) {
              console.log(formData);
              setCustomineFrom(formData.customaudit.template_detail);
              setAuditName(formData.customaudit.auditname);
              setTemplatename(formData.customaudit.templatename);
            } else {
                console.error('Error:', response.statusText);
            }
        }

        GetCustomTemplate();
    },[])

    const saveFormDetails = async (type) => {
        try {
            let requestData = null; // Change const to let
            if (type === "submit") {
                requestData = {
                    formData: customineFrom,
                    auditname:auditname,
                    templatename:templatename,
                    isSubmit: true
                };
            } else {
                requestData = {
                    formData: customineFrom,
                    auditname:auditname,
                    templatename:templatename
                };
            }
            // console.log(requestData);
            const res = await fetch(`${BASE_URL}/editcustomform/${customid}`,{
                method:"PUT",
                headers:{
                    "Content-Type": "application/json"
            },
            body:JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            toast.success(resultdata.message);
            navigate('/user');
        } catch (error) {
            toast.error("Failed to save audit:",error);
            console.error('Failed to save audit:', error);
        }
    };

    return (
        <div>
            <div className='d-flex gap-3 mb-2'>
                <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={auditname} onChange={(e)=> setAuditName(e.target.value)}/>
                <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("save")}>Save</Button>
                {usertype === "HOD" ? <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("submit")}>Submit</Button> : null}
            </div>
            {customineFrom && customineFrom.map((key,value) => (
                // <div key={value}>1</div>
                <EditAccordion key={key.header_name} title={key.header_name} content={key.header_subheading} accordionindex={value}/>  
            ))}
        </div>
    )
}

export default CustomFormEdit