import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';


const ViewAccordion = ({ title, content, accordionindex }) => {
  const expand = accordionindex === 0 ? true : false;
  return (
    <Accordion defaultExpanded={expand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Accordin_Time_Check_${title}`}>
        <h4 className='fw-bolder'>{title.replace(/_/g, " ")}</h4>
      </AccordionSummary>
      <AccordionDetails>
      {Object.entries(content).map(([key, value]) => (
        <div className='row' key={key}>
            <label className="mb-3 fw-bolder">{value[0].name.replace(/_/g, " ")}</label>
            {value.map((headvalue, headindex) => (
                <div className='row' key={headindex}>
                    {headvalue.Observation &&
                        <div className='col-md-3 mb-3'>
                            <label className='mb-1'>&nbsp;</label>
                            <TextField
                                size='small'
                                multiline
                                className='w-100'
                                label="Observation"
                                variant="outlined"
                                disabled
                                value={headvalue.Observation === true ? '' : headvalue.Observation}
                            />
                        </div>
                    }
                    {headvalue.Before_Pic !== true ? (
                        <div className='col-md-2 mb-3'>
                            <label className='mb-1'>Before Pic</label><br />
                            <img src={`/images/${headvalue.Before_Pic}`} alt={headvalue.Before_Pic} style={{ width: '100%' }} />
                        </div>
                    ) : (
                        <div className='col-md-2 mb-3'>
                            <label className='mb-1'>Before Pic</label><br />
                            <span>No Image</span>
                        </div>
                    )}
                    { 
                      headvalue.After_Pic !== true ? <div className='col-md-2 mb-3'>
                          <label className='mb-1'>After Pic</label><br/>
                          <img src={`/images/${headvalue.After_Pic}`} alt={headvalue.After_Pic} style={{width:'100%'}}/>
                      </div> : <div className='col-md-2 mb-3'> <label className='mb-1'>After Pic</label><br/> 
                            <span>No Image</span>
                      </div>
                    }{ 
                        headvalue.Target ? <div className='col-md-2 mb-3'>
                            <label className='mb-1'>&nbsp;</label>
                            <TextField multiline size='small' className='w-100' label="Target" variant="outlined" disabled
                                value={headvalue.Target === true ? '' : headvalue.Target}/>
                        </div> : null
                      }
                      { 
                        headvalue.Responsibility ? <div className='col-md-3 mb-3'>
                            <label className='mb-1'>&nbsp;</label>
                            <TextField multiline size='small' className='w-100' label="Responsibility" variant="outlined" disabled
                                value={headvalue.Responsibility === true ? '' : headvalue.Responsibility}/>
                        </div> : null
                      }
                </div>
            ))}
        </div>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default ViewAccordion


// {Object.entries(content).map(([key, value]) => (
//   <div className='row' key={key}>
//     <label className="mb-3 fw-bolder">{value[0].name.replace(/_/g, " ")}</label>
//     {value.map((headindex,headvalue) =>{
//         {headvalue.Observation &&
//           <div className='col-md-3 mb-3'>
//               <label className='mb-1'>&nbsp;</label>
//               <TextField
//                   size='small'
//                   className='w-100'
//                   label="Observation"
//                   variant="outlined"
//                   disabled
//                   value={headvalue.Observation === true ? '' :  headvalue.Observation}
//               />
//           </div>
//       }
//       { 
//         headvalue.Before_Pic !== true ? <div className='col-md-2 mb-3'>
//             <label className='mb-1'>Before Pic</label><br/>
//             <img src={`/images/${headvalue.Before_Pic}`} alt={headvalue.Before_Pic} style={{width:'100%'}}/>
//         </div> : <div className='col-md-2 mb-3'> <label className='mb-1'>After Pic</label><br/> 
//               <span>No Image</span>
//         </div>
//       }{ 
//         headvalue.After_Pic !== true ? <div className='col-md-2 mb-3'>
//             <label className='mb-1'>After Pic</label><br/>
//             <img src={`/images/${headvalue.After_Pic}`} alt={headvalue.After_Pic} style={{width:'100%'}}/>
//         </div> : <div className='col-md-2 mb-3'> <label className='mb-1'>After Pic</label><br/> 
//               <span>No Image</span>
//         </div>
//       }{ 
//           headvalue.Target ? <div className='col-md-2 mb-3'>
//               <label className='mb-1'>&nbsp;</label>
//               <TextField size='small' className='w-100' label="Target" variant="outlined" disabled
//                   value={headvalue.Target === true ? '' : headvalue.Target}/>
//           </div> : null
//         }
//         { 
//           headvalue.Responsibility ? <div className='col-md-3 mb-3'>
//               <label className='mb-1'>&nbsp;</label>
//               <TextField size='small' className='w-100' label="Responsibility" variant="outlined" disabled
//                   value={headvalue.Responsibility === true ? '' : headvalue.Responsibility}/>
//           </div> : null
//         }
//     })}
//   </div>
// ))}