import React, {useContext,useEffect, useState}  from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { F_11 } from './F_11'
import AccordionItem from './AccordionItem';
import { AllFroms } from '../../store/AppContext';
import BASE_URL from '../../config';
import toast from 'react-hot-toast';

const F_11Form = () => {

  let { auditid } = useParams();

  const location = useLocation();
  // const [isedit , setIsedit] = useState(false);
  const isedit = new URLSearchParams(location.search).get('edit');

  const { f_11From, setF_11From, usertype } = useContext(AllFroms);

  const [formname, setFormname] = useState('');
  
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`${BASE_URL}/getsingleaudit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ auditid })
        });
        const resultdata = await res.json();
        if(isedit === "true"){
          setF_11From(resultdata.F_11);
        }
        setFormname(resultdata.audit_name);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [auditid]);

  const headermapping = {
    basicorder:'The Basic Order',
    cleanupsteps:'Clean up 3 Steps',
    manufacturing:'Manufaturing (Assembly)',
    equipment_maintenance:'Equipmant Maintenance',
    smt:'SMT',
    injection:'Injection',
    press:'Press',
    warehouse:'Warehouse',
    tools:'Tools',
    esd:'ESD'
  }

  const saveFormDetails = async (type) => {
    try {
        let requestData = null; // Change const to let
        if (type === "submit") {
            requestData = {
                formData: f_11From,
                auditid: auditid,
                audit_name:formname,
                formname: "F_11",
                isSubmit: true
            };
        } else {
            requestData = {
                formData: f_11From,
                auditid: auditid,
                audit_name:formname,
                formname: "F_11"
            };
        }
        const res = await fetch(`${BASE_URL}/saveform`,{
            method:"POST",
            headers:{
              "Content-Type": "application/json"
            },
        body:JSON.stringify(requestData)
        });
        const resultdata = await res.json();
        toast(resultdata.message);
        window.location.href = "/user";
    } catch (error) {
        console.error('Upload failed:', error);
    }
};

  return (
    <div>
        <div className='d-flex gap-3 mb-2'>
            <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={formname} onChange={(e)=> setFormname(e.target.value)}/>
            <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("save")}>Save</Button>
            {isedit === "true" && usertype === "HOD" ? <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("submit")}>Submit</Button> : null}
        </div>
        {Object.entries(F_11).map(([key, value],index) => (
            <AccordionItem key={key} title={headermapping[key]} hreader={key} content={value} accordionindex={index} mainheader={key}/>
        ))}
    </div>
  )
}

export default F_11Form