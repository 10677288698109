import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AccordionItem from './AccordionItem';
import { AllFroms } from '../../store/AppContext';
import BASE_URL from '../../config';
import toast from 'react-hot-toast';


const CustomForm = () => {
    const navigate = useNavigate();
    
    let { templateid } = useParams();

    const location = useLocation();

    const customauditname = new URLSearchParams(location.search).get('auditname');
    
    const { customineFrom , setCustomineFrom } =  useContext(AllFroms);
    
    const [templateData , setTemplateData] = useState({});
    const [auditname , setAuditName] = useState(customauditname);

    useEffect(()=>{
        async function GetCustomTemplate()
        {
            const response = await fetch(`${BASE_URL}/gettemplate`, {
                method: 'POST',
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({templateid})
            });
            var formData = await response.json();
            if (response.ok) {
                setTemplateData(formData.TemplateDetails);
                setCustomineFrom(formData.TemplateDetails.header_details);
            } else {
                console.error('Error:', response.statusText);
            }
        }

        GetCustomTemplate();
    },[])

    const saveFormDetails = async () => {
        try {
            const requestData = {
                formData: customineFrom,
                auditname:auditname,
                templatename:templateData.template_name
            };
            const res = await fetch(`${BASE_URL}/savecustomform`,{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
            },
            body:JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            toast.success(resultdata.message);
            navigate('/user');
        } catch (error) {
            toast.error("Something Went Wrong:",error);
            console.error('Upload failed:', error);
        }
        // console.log(customineFrom);
    };

    return (
        <div>
            <div className='d-flex justify-content-center'>
                <span>{templateData.template_name}</span>
            </div>
            <div className='d-flex gap-3 mb-2'>
                <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={auditname} onChange={(e)=> setAuditName(e.target.value)}/>
                <Button variant="contained" style={{ width: '150px' }} onClick={saveFormDetails}>Save</Button>
            </div>
            <div className='mb-5'>
                {templateData.header_details && templateData.header_details.map((details, index) => (
                    <AccordionItem key={details.header_name} title={details.header_name} content={details} accordionindex={index}/>  
                ))}
            </div>
        </div>
    )
}

export default CustomForm