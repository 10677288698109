import React , {useContext} from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import BASE_URL from '../../config';

const AccordionItem = ({ title, content, accordionindex }) => {
    
    const { dailyprocessFrom, setDailyProcessFrom,usertype } = useContext(AllFroms);

    const location = useLocation();
    
    const isedit = new URLSearchParams(location.search).get('edit');

    function setLogFormDetails(mainfield, subfield,field,value) {
        setDailyProcessFrom(prevState => ({
            ...prevState,
            [mainfield]: {
                ...prevState[mainfield],
                [subfield]: {
                    ...prevState[mainfield][subfield],
                    [field]:value
                }
            }
        }));
    }

    async function setLogFormImages(mainfield, subfield, field, event) {
        const file = event.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        if(file)
        {
            if (file.size > maxSize) {
                toast.error("File size should not exceed 5MB.");
                event.target.value = '';
                return;
            }
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                var filepath = await response.json();
                setLogFormDetails(mainfield,subfield,field,filepath.filePath)
            } else {
                console.error('Error:', response.statusText);
            }
        }
    }

    const expand = accordionindex === 0 ? true : false;
    return (
        <Accordion defaultExpanded={expand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Accordin_Time_Check_${title}`}>
                <h4 className='fw-bolder'>{title.replace(/_/g, ' ')}</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className='d-flex flex-column'>
                    {
                        Object.entries(content).map(([key, value]) => (
                            <div className='row' key={key}>
                                <label className='mb-2 fw-bolder'>{value.label}</label>
                                <div className='col-md-4 mb-3'>
                                    <TextField size='small' className='w-100' value={dailyprocessFrom?.[title]?.[key].observation} label="Observation" variant="outlined"
                                    onChange={(e)=> setLogFormDetails(title,key,"observation",e.target.value)}/>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <TextField size='small' className='w-100' value={dailyprocessFrom?.[title]?.[key].remarks} label="Remarks" variant="outlined"
                                    onChange={(e)=> setLogFormDetails(title,key,"remarks",e.target.value)}/>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    {isedit === 'true' ? (
                                        dailyprocessFrom?.[title]?.[key].imageurl ? (
                                            <div>
                                                <img src={'/images/'+ dailyprocessFrom?.[title]?.[key].imageurl} alt='image' style={{width:'100%'}}/>
                                                <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                    <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e)=> setLogFormImages(title,key,"imageurl",e)} />
                                                </Form.Group>
                                            </div>
                                        ) : (
                                            <Form.Group controlId="formFile" className='w-100'>
                                                <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e)=> setLogFormImages(title,key,"imageurl",e)} />
                                            </Form.Group>
                                        )
                                    ) : (
                                            <Form.Group controlId="formFile" className='w-100'>
                                                <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e)=> setLogFormImages(title,key,"imageurl",e)} />
                                            </Form.Group>
                                    )}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionItem;
