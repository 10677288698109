import React,{useState} from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import BASE_URL from '../../config';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const CreateUser = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setuserDetails] = useState({
    name:'',
    password:'',
    usertype:'User',
  })


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSelect = (event) => {
    setuserDetails(pre => ({
        ...pre,
        usertype:event.target.value
    }));
  }

  const setUserdetailsFunction = (field,value) =>{
    setuserDetails(pre => ({
        ...pre,
        [field]:value
    }));
  }

  const saveUserDetails = async (e) =>{
    try{
        e.preventDefault();
        const res = await fetch(`${BASE_URL}/register`,{
          method:"POST",
          headers:{
            "Content-Type": "application/json"
          },
          body:JSON.stringify(userDetails)
        });
        const resultdata = await res.json();
        toast.success(resultdata.message);
        if(res.status === 200)
        {
          navigate('/admin/usertable');
        }
    }catch(err)
    {
      toast.error("User Registration Failed:", err);
      console.error('User Registration Failed:', err);
    }
  }

  return (
    <div>
        <div className='d-flex justify-content-center mb-3'>
            <span className='fs-4 fw-bolder'>Create User</span>
        </div>
        <form onSubmit={saveUserDetails}>
            <div className='d-flex flex-column gap-4'>
                <TextField label="User Name" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userDetails.name} onChange={(e) => setUserdetailsFunction("name",e.target.value)} required/>
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="outlined" required
                    size="medium"
                    className="w-100"
                    value={userDetails.password} onChange={(e) => setUserdetailsFunction("password",e.target.value)}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userDetails.usertype}
                    label="User Type"
                    onChange={handleSelect}
                    >
                    <MenuItem value='User'>User</MenuItem>
                    <MenuItem value='HOD'>Head of Department</MenuItem>
                    <MenuItem value='Admin'>Admin</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" style={{ width: '150px' }} type='submit'>Save</Button>
            </div>
        </form>
    </div>
  )
}

export default CreateUser