import React , {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import BASE_URL from '../../config';

const AccordionItem = ({ title, hreader, content, accordionindex, mainheader }) => {
    
    const { f_11From, setF_11From , usertype } = useContext(AllFroms);

    const location = useLocation();
    // const [isedit , setIsedit] = useState(false);
    const isedit = new URLSearchParams(location.search).get('edit');

    function setLogFormDetails(mainfield, field, subfield, value,type) {
        if(type === "value")
        {
            if(field === mainfield)
            {
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [subfield]: {
                            ...prev[mainfield][subfield],
                            textfieldvalue: value
                        }
                    }
                }));
            }else{
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [field]: {
                            ...prev[mainfield][field],
                            [subfield]: {
                                ...prev[mainfield][field][subfield],
                                textfieldvalue: value
                            }
                        }
                    }
                }));
            }
        }
        else if(type === "observation")
        {
            if(field === mainfield)
            {
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [subfield]: {
                            ...prev[mainfield][subfield],
                            observations: value
                        }
                    }
                }));
            }else{
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [field]: {
                            ...prev[mainfield][field],
                            [subfield]: {
                                ...prev[mainfield][field][subfield],
                                observations: value
                            }
                        }
                    }
                }));
            }
        }
        else if(type === "beforeimage"){
            if(field === mainfield)
            {
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [subfield]: {
                            ...prev[mainfield][subfield],
                            beforeimg: value
                        }
                    }
                }));
            }else{
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [field]: {
                            ...prev[mainfield][field],
                            [subfield]: {
                                ...prev[mainfield][field][subfield],
                                beforeimg: value
                            }
                        }
                    }
                }));
            }
        }
        else if(type === "afterimage"){
            if(field === mainfield)
            {
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [subfield]: {
                            ...prev[mainfield][subfield],
                            afterimg: value
                        }
                    }
                }));
            }else{
                setF_11From(prev => ({
                    ...prev,
                    [mainfield]: {
                        ...prev[mainfield],
                        [field]: {
                            ...prev[mainfield][field],
                            [subfield]: {
                                ...prev[mainfield][field][subfield],
                                afterimg: value
                            }
                        }
                    }
                }));
            }
        }
    }

    async function setLogFormImages(mainfield, subfield, field, event,iamgetype) {
        const file = event.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        if(file)
        {
            if (file.size > maxSize) {
                toast.error("File size should not exceed 5MB.");
                event.target.value = '';
                return;
            }
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                const filepath = await response.json();
                setLogFormDetails(mainfield,subfield,field,filepath.filePath,iamgetype);
            } else {
                console.error('Error:', response.statusText);
            }
        }
    }

    const expand = accordionindex === 0 ? true : false;
    return (
        <Accordion defaultExpanded={expand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Accordin_Time_Check_${title}`}>
                <h4 className='fw-bolder'>{title}</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className='d-flex flex-column gap-3'>
                    {
                        Object.entries(content).map(([key, value], index) => (
                            <div key={key}>
                                {value.textfieldvalue === '' ? (
                                    <div className='row'>
                                      <div className="col-md-3 mb-2">
                                        <TextField disabled={usertype !== 'HOD'} size='small' className='w-100' value={f_11From?.[mainheader]?.[hreader]?.[key].textfieldvalue} label={value.fieldlable} variant="outlined" helperText="Maximum allowable value: 1" onChange={(e)=> setLogFormDetails(mainheader,hreader,key,e.target.value,"value")}/>
                                        {/* onChange={(e)=> setLogFormDetails("basicorder","compliance","openclose",e.target.value,"value")} */}
                                      </div>
                                      <div className="col-md-3 mb-2">
                                        <TextField disabled={usertype !== 'HOD'} size='small' className='w-100' value={f_11From?.[mainheader]?.[hreader]?.[key].observations} label="Observations" variant="outlined" onChange={(e)=> setLogFormDetails(mainheader,hreader,key,e.target.value,"observation")}/>
                                        {/* onChange={(e)=> setLogFormDetails("basicorder","compliance","openclose",e.target.value,"observation")} */}
                                      </div>
                                      <div className="col-md-3 mb-2">
                                        {isedit === 'true' ? (
                                            mainheader === hreader ? (
                                                <div>
                                                    {f_11From[mainheader][key].beforeimg ? <img src={'/images/'+f_11From[mainheader][key].beforeimg} alt='image' style={{width:'100%'}}/> : <div>No Image</div>}
                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                        <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(mainheader, hreader, key, e.target.files, "beforeimage")} />
                                                    </Form.Group>
                                                </div>
                                            ) :(
                                                <div>
                                                    {f_11From[mainheader][hreader][key].beforeimg ? <img src={'/images/'+f_11From[mainheader][hreader][key].beforeimg} alt='image' style={{width:'100%'}}/> : <div>No Image</div>}
                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                        <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(mainheader, hreader, key, e.target.files, "beforeimage")} />
                                                    </Form.Group>
                                                </div>
                                            )
                                        ) : (
                                            <Form.Group controlId="formFile" className='w-100'>
                                                <Form.Control disabled={usertype !== 'HOD'} type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "beforeimage")} />
                                            </Form.Group>
                                        )}
                                      </div>
                                      <div className="col-md-3 mb-2">
                                      {isedit === 'true' ? (
                                            f_11From?.[mainheader]?.[hreader]?.[key].afterimg ? (
                                                <div>
                                                    <img src={'/images/'+f_11From[mainheader][hreader][key].afterimg} alt='image' style={{width:'100%'}}/>
                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                        <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "afterimage")} />
                                                    </Form.Group>
                                                </div>
                                            ) : (
                                                <Form.Group controlId="formFile" className='w-100'>
                                                    <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "afterimage")} />
                                                </Form.Group>
                                            )
                                        ) :
                                            <Form.Group controlId="formFile" className='w-100'>
                                                <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "afterimage")} />
                                            </Form.Group>
                                        }
                                      </div>
                                    </div>
                                ) : (
                                   key !== 'label' && <AccordionItem key={key} title={value.label} hreader={key} content={value} accordionindex={index} mainheader={mainheader} />
                                )}
                            </div>
                        ))
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionItem;

// f_11From?.[mainheader]?.[hreader]?.[key].beforeimg ? (
//     <div>
//         <img src={'/images/'+f_11From[mainheader][hreader][key].beforeimg} alt='image' style={{width:'100%'}}/>
//         <Form.Group controlId="formFile" className='w-100 mt-2'>
//             <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "beforeimage")} />
//         </Form.Group>
//     </div>
// ) : (
//     <Form.Group controlId="formFile" className='w-100'>
//         <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(mainheader, hreader, key, e, "beforeimage")} />
//     </Form.Group>
// )