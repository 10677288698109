import React , {useContext} from 'react';
import { toast } from "react-toastify";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import { TextField} from '@mui/material';
import Form from 'react-bootstrap/Form';
import BASE_URL from '../../config';

const EditAccordion = ({ content, title ,accordionindex }) => {
    
    const { customineFrom, setCustomineFrom,usertype } = useContext(AllFroms);

    // const handleAddSubheading = (headIndex, subheadIndex,newRowData)=>{
    //     setCustomineFrom(prevHeaders => {
    //         const updatedHeaders = prevHeaders.map((header, index) => {
    //             if (index === headIndex) {
    //                 const updatedSubheadings = header.header_subheading.map((sub, subIndex) => {
    //                     if (subIndex === subheadIndex) {
    //                         // Clone the existing subheading array and push the new row into it
    //                         const newSubheadingArray = [...sub, newRowData];
    //                         return newSubheadingArray;
    //                     }
    //                     return sub;
    //                 });
    //                 return {
    //                     ...header,
    //                     header_subheading: updatedSubheadings
    //                 };
    //             }
    //             return header;
    //         });
    //         return updatedHeaders;
    //     });
    // }

    const handleInputChange = (headIndex, subheadIndex, lastindex, field, value) => {
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    };

    async function setLogFormImages(mainfield, subfield,headindex, field, event) {
        const file = event.target.files[0];
        const maxSize = 5 * 1024 * 1024;

        if(file)
        {
            if (file.size > maxSize) {
                toast.error("File size should not exceed 5MB.");
                event.target.value = '';
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                var filepath = await response.json();
                handleInputChange(mainfield,subfield,headindex,field,filepath.filePath)
            } else {
                console.error('Error:', response.statusText);
            }
        }
    }

    const expand = accordionindex === 0 ? true : false;
    return (
        <Accordion defaultExpanded={expand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Custom_Accordin_${title}`}>
                <h4 className='fw-bolder'>{title.replace(/_/g, " ")}</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className='d-flex flex-column gap-3'>
                    {
                        content ? (
                            content.map((subheading,subheadingIndex) => (
                                <div key={subheadingIndex}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className='fw-bolder fs-6 mb-2'>{subheading[0].name}</label>
                                    </div>
                                    {customineFrom[accordionindex].header_subheading[subheadingIndex].map((headname,headindex) => (
                                        <div className='row' key={headindex}>
                                            {headname.Observation &&
                                                <div className='col-md-4 mb-3'>
                                                    <label className='mb-1'>&nbsp;</label>
                                                    <TextField
                                                        size='small'
                                                        className='w-100'
                                                        multiline
                                                        label="Observation"
                                                        variant="outlined"
                                                        value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation === true ? '' : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation}
                                                        disabled={usertype !== 'HOD'}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value === '' ? true : e.target.value;
                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Observation", newValue);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            { 
                                                headname.Before_Pic === true  ?
                                                    <div className='col-md-4 mb-3'>
                                                        <label className='mb-1'>Before Pic</label><br/>
                                                        <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                            <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e)=> setLogFormImages(accordionindex, subheadingIndex,headindex,"Before_Pic",e)}/>
                                                        </Form.Group>
                                                    </div>
                                                : <div className='col-md-4 mb-3'>
                                                        <label className='mb-1'>Before Pic</label><br/>
                                                        <img src={'/images/'+ headname.Before_Pic} alt={headname.Before_Pic} style={{width:'100%'}}/>
                                                        <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                            <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e)=> setLogFormImages(accordionindex, subheadingIndex,headindex,"Before_Pic",e)}/>
                                                        </Form.Group>
                                                    </div>
                                            }{ 
                                                headname.After_Pic === true ?
                                                <div className='col-md-4 mb-3'>
                                                        <label className='mb-1'>After Pic</label><br/>
                                                        <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                        <Form.Control type="file" onChange={(e)=> setLogFormImages(accordionindex, subheadingIndex,headindex,"After_Pic",e)}/>
                                                        </Form.Group>
                                                    </div>
                                                : 
                                                <div className='col-md-4 mb-3'>
                                                    <label className='mb-1'>After Pic</label><br/>
                                                    <img src={'/images/'+ headname.After_Pic} alt={headname.After_Pic} style={{width:'100%'}}/>
                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                        <Form.Control type="file" onChange={(e)=> setLogFormImages(accordionindex, subheadingIndex,headindex,"After_Pic",e)}/>
                                                    </Form.Group>
                                                </div>
                                            }
                                            {
                                                headname.Target &&
                                                <div className='col-md-4 mb-3'>
                                                    <label className='mb-1'>&nbsp;</label>
                                                    <TextField multiline size='small' className='w-100' label="Target" variant="outlined"
                                                        disabled={usertype !== 'HOD'}
                                                        value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target === true ? "" : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target}
                                                        // onChange={(e) => handleInputChange(accordionindex, subheadingIndex, "Target", e.target.value)}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value === '' ? true : e.target.value;
                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Target", newValue);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                headname.Responsibility &&
                                                <div className='col-md-4 mb-3'>
                                                    <label className='mb-1'>&nbsp;</label>
                                                    <TextField  multiline size='small' className='w-100' label="Responsibility" variant="outlined"
                                                        value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Responsibility === true ? "" : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Responsibility}
                                                        // onChange={(e) => handleInputChange(accordionindex, subheadingIndex, "Responsibility", e.target.value)}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value === '' ? true : e.target.value;
                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Responsibility", newValue);
                                                        }} 
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            ))
                        ):null
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default EditAccordion;