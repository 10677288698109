import React, {useContext,useEffect, useState}  from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FirstFive } from './FirstFive';
import AccordionItem from './AccordionItem';
import { AllFroms } from '../../store/AppContext';

import BASE_URL from '../../config';
import toast from 'react-hot-toast';

const FirstFiveForm = () => {

    let { auditid } = useParams();

    const [auditname,setAuditName]  = useState('');

    const location = useLocation();
    // const [isedit , setIsedit] = useState(false);
    const isedit = new URLSearchParams(location.search).get('edit');

    const { firstfiveformDetails,setFirstFiveFormDetails, usertype } = useContext(AllFroms);

    useEffect(() => {
        async function fetchData() {
          try {
            const res = await fetch(`${BASE_URL}/getsingleaudit`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ auditid })
            });
            const resultdata = await res.json();
            if(isedit === "true"){
                setFirstFiveFormDetails(resultdata.First_Five);
            }
            setAuditName(resultdata.audit_name);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        fetchData();
    }, [auditid]);
    
    const saveFormDetails = async (type) => {
        try {
            let requestData = null; // Change const to let
            if (type === "submit") {
                requestData = {
                    formData: firstfiveformDetails,
                    auditid: auditid,
                    audit_name:auditname,
                    formname: "First_Five",
                    isSubmit: true
                };
            } else {
                requestData = {
                    formData: firstfiveformDetails,
                    auditid: auditid,
                    audit_name:auditname,
                    formname: "First_Five"
                };
            }
            const res = await fetch(`${BASE_URL}/saveform`,{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
            },
            body:JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            toast(resultdata.message);
            window.location.href = "/user";
        } catch (error) {
            console.error('Upload failed:', error);
        }
        console.log(firstfiveformDetails);
    };

    return (
        <div>
            <div className='d-flex gap-3 mb-2'>
                <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={auditname} onChange={(e) => setAuditName(e.target.value)}/>
                <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("save")}>Save</Button>
                {isedit === "true" && usertype === "HOD" ? <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("submit")}>Submit</Button> : null}
            </div>
            {Object.entries(FirstFive).map(([key, value],index) => (
                <AccordionItem key={key} title={key} content={value} accordionindex={index}/>
            ))}
        </div>
    );
};

export default FirstFiveForm;