import React, { useState ,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import BASE_URL from './config';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [userdetails, setUserDetails] = useState({
    email:'',
    password:''
  })

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const UserDetailsChnage = (strippedId,value) =>{
    setUserDetails(prevState => ({
      ...prevState,
      [strippedId]: value
  }));
  }

  useEffect(()=>{
    const callAbout= async()=>{
        try{
        const res = await fetch(`${BASE_URL}/getauthadmin`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            },
            credentials:"include"
        })
        const data = res.json();
        if(res.status === 200){
          navigate('/admin');
        }else if(res.status === 201)
        {
          navigate('/user');
        }
        else{
          const error = new Error(res.error);
          throw error;
        }
    }catch(err)
    {
      navigate('/');
    }
    };
    callAbout()
  },[])

  const UserLogin =async (e)=>{
    try{
      e.preventDefault();
      const res = await fetch(`${BASE_URL}/login`,{
          method:"POST",
          credentials: 'include', 
          headers:{
              "Content-Type":"application/json"
          },
          body:JSON.stringify(userdetails)
      })
      const data = await res.json();
      if(res.status === 200){
        if(data.UserType === "User"){
          toast.success(data.message);
          navigate('/user');
        }else{
          toast.success(data.message);
          navigate('/admin');
        }
      }
      else{
        toast.error(data.message);
      }
    }catch(err)
    {
      console.log(err);
    }
  }
  return (
    <>
      <div className='container' style={{height:'100vh'}}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className='d-flex flex-column gap-4' style={{width:'600px'}}>
            <div className='d-flex justify-content-center'><h2>Login</h2></div>
            <form onSubmit={UserLogin} className='d-flex flex-column gap-4'>
              <TextField label="User Name" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userdetails.email} onChange={(e) => UserDetailsChnage("email",e.target.value)}/>
              <TextField
                type={showPassword ? 'text' : 'password'}
                label="Password"
                variant="outlined"
                size="medium"
                className="w-100"
                value={userdetails.password} onChange={(e) => UserDetailsChnage("password",e.target.value)}
                autoComplete="off"
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
            <Button type='submit' variant="contained">Login</Button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login