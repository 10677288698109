import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import BASE_URL from '../config';
import { toast } from "react-hot-toast";
import logo from './KUNSTOCOMLOGO.jpg';
import { useNavigate } from 'react-router-dom';

const adminPagesLinks = {
  'Templates': '',
  'Create Template': '/createtemplate',
  'Create User': '/createuser',
  'All Users': '/usertable'
};
// const hodpages = ['Create Audit'];
// const settings = ['Logout'];

const TopNavbar = ({userdetails}) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlelogout = async () =>{
    const res = await fetch(`${BASE_URL}/logout`,{
      method:"GET",
      headers:{
          Accept:"application/json",
          "Content-Type":"application/json"
        },
        credentials:"include"
      });
      const resultdata = await res.json();
      toast.success(resultdata.message);
      navigate("/");
  }

  const handleNavigation = async (page) =>{
    var url = '/admin'+ page;
    navigate(url);
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/user"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* src='KUNSTOCOMLOGO.jpg' */}
              <img src={logo}  alt='Kunstocom Logo' height={50} style={{ marginRight: '10px' }} />
              Kunstocom India Private Limited
            </Box>
            {/* <img src='../../images/KUNSTOCOMLOGO.jpg' height={50}/>
            Kunstocom India Private Limited */}
          </Typography>

          {userdetails.admin ?
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {Object.entries(adminPagesLinks).map(([page, link]) => (
                  <MenuItem key={page} onClick={() => handleNavigation(link)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
           : null}
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Kunstocom India Ltd
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {userdetails.admin &&
              Object.entries(adminPagesLinks).map(([page, link]) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(link)}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page}
                </Button>
              ))
            }
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" className='d-flex gap-2'>
              <div className='d-flex gap-2'>
                <div className='d-flex flex-column'>
                  <span>{userdetails.name}</span>
                  <span>{userdetails.usertype}</span>
                </div>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userdetails.name} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </div>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => ( */}
                <MenuItem onClick={handlelogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              {/* ))} */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default TopNavbar