import React, { useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import toast from 'react-hot-toast';
import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import Form from 'react-bootstrap/Form';
import BASE_URL from '../../config';

const AccordionItem = ({ content, title, accordionindex }) => {

    const { customineFrom, setCustomineFrom } = useContext(AllFroms);


    // const handleInputChange = (accordionIndex, subheadingIndex, headIndex, field, value) => {
    //     setCustomineFrom(prevHeaders => {
    //         const updatedHeaders = prevHeaders.map((header, index) => {
    //             if (index === accordionIndex) {
    //                 const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
    //                     if (subIndex === subheadingIndex) {
    //                         const updatedSubheading = { ...subheading };
    //                         updatedSubheading[headIndex][field] = value;
    //                         return updatedSubheading;
    //                     }
    //                     return subheading;
    //                 });
    //                 return {
    //                     ...header,
    //                     header_subheading: updatedSubheadings
    //                 };
    //             }
    //             return header;
    //         });
    //         return updatedHeaders;
    //     });
    //     console.log(customineFrom);
    // };
    


    async function setLogFormImages(mainfield, subfield,headindex, field, event) {
        const file = event.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 5MB in bytes

        if(file)
        {
            if (file.size > maxSize) {
                toast.error("File size should not exceed 5MB.");
                event.target.value = '';
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
    
            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                var filepath = await response.json();
                handleInputChange(mainfield, subfield,headindex, field, filepath.filePath)
            } else {
                console.error('Error:', response.statusText);
            }
        }
    }

    const handleAddSubheading = (headIndex, subheadIndex,newRowData)=>{
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((sub, subIndex) => {
                        if (subIndex === subheadIndex) {
                            // Clone the existing subheading array and push the new row into it
                            const newSubheadingArray = [...sub, newRowData];
                            return newSubheadingArray;
                        }
                        return sub;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    }

    const handleInputChange = (headIndex, subheadIndex, lastindex, field, value) => {
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    };

    const handleDeleteSubheading = (headerIndex ,subheadIndex,lastindex) =>{
        const updatedHeaders = [...customineFrom];
        const headerToUpdate = updatedHeaders[headerIndex].header_subheading;
        if (headerToUpdate) {
            headerToUpdate[subheadIndex].splice(lastindex, 1);
            setCustomineFrom(updatedHeaders);
        }
    }

    const expand = accordionindex === 0 ? true : false;
    return (
        <Accordion defaultExpanded={expand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Custom_Accordin_${title}`}>
                <h4 className='fw-bolder'>{title}</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className='d-flex flex-column gap-3'>
                    {content.header_subheading ? (
                        content.header_subheading.map((subheading, subheadingIndex) => (
                            <div key={subheadingIndex}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className='fw-bolder fs-6 mb-2'>{subheading[0].name}</label>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleAddSubheading(accordionindex, subheadingIndex, subheading[0])}
                                        >
                                        <AddIcon></AddIcon>
                                    </Button>
                                </div>
                                {customineFrom[accordionindex].header_subheading[subheadingIndex].map((headname,headindex) => (
                                    <div className='row' key={headindex}>
                                        {headname.Observation &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <TextField
                                                    size='small'
                                                    className='w-100'
                                                    label="Observation"
                                                    multiline
                                                    variant="outlined"
                                                    value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation === true ? '' : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value === '' ? true : e.target.value;
                                                        handleInputChange(accordionindex, subheadingIndex, headindex, "Observation", newValue);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            headname.Before_Pic &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>Before Pic</label>
                                                <Form.Group controlId="formFile" className='w-100'>
                                                    <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex,headindex, "Before_Pic", e)} />
                                                </Form.Group>
                                            </div>
                                        }
                                        {
                                            headname.After_Pic &&
                                        <   div className='col-md-4 mb-3'>
                                            <label className='mb-1'>After Pic</label>
                                            <Form.Group controlId="formFile" className='w-100'>
                                                <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex,headindex, "After_Pic", e)} />
                                            </Form.Group>
                                            </div>
                                        }
                                        {
                                            headname.Target &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <TextField multiline size='small' className='w-100' label="Target" variant="outlined"
                                                    value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target === true ? "" : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value === '' ? true : e.target.value;
                                                        handleInputChange(accordionindex, subheadingIndex, headindex, "Target", newValue);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            headname.Responsibility &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <TextField multiline size='small' className='w-100' label="Responsibility" variant="outlined"
                                                    value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Responsibility === true ? "" : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Responsibility}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value === '' ? true : e.target.value;
                                                        handleInputChange(accordionindex, subheadingIndex, headindex, "Responsibility", newValue);
                                                    }} 
                                                />
                                            </div>
                                        }
                                        {headindex > 0 ?
                                            <div className='col-md-4 mb-3'>
                                                <div className='mb-1'>&nbsp;</div>
                                                <div className='d-flex gap-5'>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleDeleteSubheading(accordionindex, subheadingIndex, headindex)}
                                                        >
                                                        <DeleteIcon></DeleteIcon>
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleAddSubheading(accordionindex, subheadingIndex, subheading[0])}
                                                        >
                                                        <AddIcon></AddIcon>
                                                    </Button>
                                                </div>
                                            </div>
                                         : null}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : null}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionItem;