import React, {useContext,useEffect,useState}  from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DailyLine } from './Dailyline';
import AccordionItem from './AccordionItem';
import { AllFroms } from '../../store/AppContext';
import BASE_URL from '../../config';
import toast from 'react-hot-toast';

const DailyLineForm = () => {
    const navigate = useNavigate();
    let { auditid } = useParams();

    const [auditname,setAuditName]  = useState('');

    const { dailyLineFrom ,setDailyLineFrom, usertype } = useContext(AllFroms);

    const location = useLocation();

    const isedit = new URLSearchParams(location.search).get('edit');

    useEffect(() => {
        async function fetchData() {
          try {
            const res = await fetch(`${BASE_URL}/getsingleaudit`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ auditid })
            });
            const resultdata = await res.json();
            if(isedit === "true"){
                setDailyLineFrom(resultdata.Daily_Line);
            }
            setAuditName(resultdata.audit_name);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        fetchData();
    }, [auditid]);

    const saveFormDetails = async (type) => {
        try {
            let requestData = null; // Change const to let
            if (type === "submit") {
                requestData = {
                    formData: dailyLineFrom,
                    auditid: auditid,
                    audit_name:auditname,
                    formname: "Daily_Line",
                    isSubmit: true
                };
            } else {
                requestData = {
                    formData: dailyLineFrom,
                    auditid: auditid,
                    audit_name:auditname,
                    formname: "Daily_Line"
                };
            }
            const res = await fetch(`${BASE_URL}/saveform`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            toast(resultdata.message);
            navigate('/user');
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };
    

    return (
        <div>
            <div className='d-flex gap-3 mb-2'>
                <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={auditname} onChange={(e) => setAuditName(e.target.value)}/>
                <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("save")}>Save</Button>
                {isedit === "true" && usertype === "HOD" ? <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("submit")}>Submit</Button> : null}
            </div>
            {Object.entries(DailyLine).map(([key, value],index) => (
                <AccordionItem key={key} title={key} content={value} accordionindex={index}/>  
            ))}
        </div>
    );
};

export default DailyLineForm;